import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard() {
  return (
    <Card style={{ marginBottom: 25 }} sx={{  minWidth: 275 }}>
      <CardContent>
        <Typography  align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Extra-Dimentinal BONK
        </Typography>
        <Typography  align="center" variant="h5" component="div">
          Mint For 69420 BONK 
        </Typography>
      </CardContent>
    </Card>
  );
}