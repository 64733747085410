import { withWidth } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            inversolabs
          </Typography>
          <Button color="inherit">Twitter</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
const Container = styled("div")`
    widht: 100vw;
    height:150px;
    background-color: #b026ff;
    
    display: Flex;
    justify-content: center;
    align-items: center;

    `



        
        

const Title = styled('h1')`
    margin: 0;
   

`
const Link = styled('a')`
fontsize: 1rem;
color: initial;
text-decoration: none;
Cursor: hover;


`
const ShowCaseImage = styled("img")`
border-radius: 13%;
width: 100%;
height: 350px;
object-fit: cover;
object-position:top;
margin-bottom: 30px;
`

