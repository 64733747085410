import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function BoxComponent() {
  return (
    <Box  justifyContent="center"
  alignItems="center" component="span" sx={{}}>
 <Typography
            variant="caption"
            align="center"
            display="block"
            style={{ marginTop: 10, color: 'grey' }}
          >
          INVERSO LABS 2023
          </Typography>
    </Box>
  );
}